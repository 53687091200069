import { useCallback, useState } from 'react'

type AsyncFunction<P extends unknown[] = unknown[], R = unknown> = (...args: P) => Promise<R>
type MutationOptions<R = unknown> = {
  onSuccess?: (data: R) => void
  onError?: (error: unknown) => void
}

type MutationState = 'idle' | 'loading' | 'error' | 'success'

function useCustomMutation<P extends unknown[] = unknown[], R = unknown>(
  asyncFunction: AsyncFunction<P, R>,
  options?: MutationOptions<R>,
) {
  const [status, setStatus] = useState<MutationState>('idle')
  const { onSuccess, onError } = options || {}

  const mutate = useCallback(
    async (...params: P) => {
      try {
        setStatus('loading')
        const data = await asyncFunction(...params)
        setStatus('success')
        typeof onSuccess === 'function' && onSuccess(data)

        setTimeout(() => {
          setStatus('idle')
        }, 3000) //return to idle after 3 seconds
        return data
      } catch (error) {
        setStatus('error')
        typeof onError === 'function' && onError(error)
        throw error // rethrow the error so the caller can catch it
      }
    },
    [asyncFunction, onError, onSuccess],
  )

  return {
    mutate,
    status,
    isLoading: status === 'loading',
    isSuccess: status === 'success',
    isError: status === 'error',
    isIdle: status === 'idle',
  }
}

export default useCustomMutation
