import { NewBackEndApiResponseData } from 'shared/types/ApiResponseData'
import { apiClient } from 'utils/apiClient'
import { withApiErrorHandled } from 'utils/common'

export const addReferralCode = async (referralCode: string) => {
  const response = await withApiErrorHandled(apiClient.post)('/v2/auth/referral', {
    referralCode: referralCode.toLowerCase().trim(),
  })

  return response.data as NewBackEndApiResponseData<unknown>
}

export default addReferralCode
