import { useMemo } from 'react'
import useFetchingMetadata from 'shared/hooks/data-fetching/useFetchingMetadata'

type RoleInput = string | null | undefined // undefined is for the case when the user is not logged in, or the current user's role is not fetched yet

type CheckRoleResponse = {
  isUserHenry: boolean
  isUserCreator: boolean
  isUserCollector: boolean
  isUserCA: boolean
  isUserIA: boolean
  isFetchingRoleMap: boolean
  isRoleMapReady: boolean
}

/**
 * Custom hook for checking user role.
 *
 * @param userRole - The user role to check.
 * @returns An object containing the results of the role checks.
 */

const useCheckingRole = (userRole: RoleInput) => {
  const { metadata, isLoading: isFetchingRoleMap } = useFetchingMetadata()
  const roleMap = metadata?.roleMap

  const checkRoleResponse: CheckRoleResponse = useMemo(() => {
    const isUserHenry = Boolean(roleMap && userRole === roleMap['henry'])
    const isUserCreator = Boolean(roleMap && userRole === roleMap['creator'])
    const isUserCollector = Boolean(roleMap && userRole === roleMap['collector'])
    const isUserCA = Boolean(roleMap && userRole === roleMap['cohart-advisor'])
    const isUserIA = Boolean(roleMap && userRole === roleMap['independent-advisor'])
    const isRoleMapReady = Boolean(roleMap)

    return {
      isUserHenry,
      isUserCreator,
      isUserCollector,
      isUserCA,
      isUserIA,
      isFetchingRoleMap,
      isRoleMapReady,
    }
  }, [isFetchingRoleMap, roleMap, userRole])

  return checkRoleResponse
}

export default useCheckingRole
