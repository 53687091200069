import cn from 'classnames'
import SideBar from 'shared/components/display/SideBar'
import { Dispatch, ReactNode, SetStateAction, createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'

export type AppLayout = {
  fullHeightSidebar?: boolean // indicates if the sidebar is full height (when header is hidden)
  isMobileSidebarVisible?: boolean // 'isMobileSidebarVisible' is used to indicate if the sidebar is shown on the mobile
}

type AppLayoutContextType = {
  appLayout: AppLayout
  setAppLayout: Dispatch<SetStateAction<AppLayout>>
}

export const defaultAppLayout: AppLayout = {
  fullHeightSidebar: false,
  isMobileSidebarVisible: false, // default to be hidden on mobile
}

// This context is used to store app layout that are shared across the app
const AppLayoutContext = createContext<AppLayoutContextType>({
  appLayout: defaultAppLayout,
  setAppLayout: () => undefined,
})

type AppLayoutProviderProps = {
  children: ReactNode
}

export const AppLayoutProvider = ({ children }: AppLayoutProviderProps) => {
  const [appLayout, setAppLayout] = useState<AppLayout>(defaultAppLayout)
  const { isMobileSidebarVisible } = appLayout
  const router = useRouter()

  const contextValue: AppLayoutContextType = useMemo(() => {
    return { appLayout, setAppLayout }
  }, [appLayout, setAppLayout])

  const closeMobileSidebarMenu = useCallback(() => {
    setAppLayout((prev) => ({ ...prev, isMobileSidebarVisible: false }))
  }, [])

  // Side-effects

  // disable body scroll when mobile sidebar is visible
  useEffect(() => {
    if (isMobileSidebarVisible) {
      document.body.style.overflow = 'hidden'
    }
    if (!isMobileSidebarVisible) {
      document.body.style.overflow = 'unset'
    }
  }, [isMobileSidebarVisible])

  // close mobile sidebar menu when route changes
  useEffect(() => {
    const handleRouteChange = () => {
      closeMobileSidebarMenu()
    }

    router.events.on('routeChangeStart', handleRouteChange)

    // Cleanup the event listener on component unmount
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router.events, closeMobileSidebarMenu])

  return (
    <AppLayoutContext.Provider value={contextValue}>
      {/* mobile overlay */}
      <div
        className={cn(
          'fixed left-0 top-20 z-[49] bg-black/60 lg:hidden',
          isMobileSidebarVisible ? 'h-[calc(100vh-80px)] w-screen opacity-100' : 'h-0 w-0 origin-center opacity-0',
        )}
        onClick={closeMobileSidebarMenu}
      />

      {/* mobile sidebar */}
      <div
        className={cn(
          'fixed right-0 z-50 w-[300px] transition-all',
          'top-20 h-[calc(100vh-80px)]', // always shown below the header
          'lg:hidden', // always hidden on desktop
          'shadow-lg outline outline-1 outline-black/10',
          isMobileSidebarVisible ? 'translate-x-0' : 'translate-x-full',
        )}
      >
        <div className="h-full w-full bg-white">
          <SideBar isDesktopSidebar={false} />
        </div>
      </div>

      {/* content */}
      {children}
    </AppLayoutContext.Provider>
  )
}

export default AppLayoutContext
