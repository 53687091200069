import { GOOGLE_ANALYTICS_GA4, IS_PROD } from 'configs'
import { GTMEventName } from 'shared/types/V3/Gtm'
import { createHash } from 'crypto'
import { UserProfile } from 'shared/types/V3/User'

declare global {
  interface Window {
    gtag: (gtagType: string, action: string, config: unknown) => unknown
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: Record<string, any>[]
  }
}

type TrackEventProps = {
  action: string
  params?: unknown
}

// Tracking a custom event and send it to Google Analytics service
export const trackEvent = ({ action, params }: TrackEventProps) => {
  // only track event on production environment
  if (IS_PROD) {
    window.gtag('event', action, params || {})
  }
}

// Tracking a page_view event and send it to Google Analytics service
export const trackPageView = (pagePath: string) => {
  // only track event on production environment
  if (IS_PROD && GOOGLE_ANALYTICS_GA4) {
    window.gtag('config', GOOGLE_ANALYTICS_GA4, { page_path: pagePath })
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const gtmDataLayerPush = (event: GTMEventName, properties?: Record<string, any>) => {
  window.dataLayer?.push({ event, ...properties })
}

export const getGTMUserData = (userProfile?: UserProfile | null) => {
  const sha256_email_address = userProfile?.email ? createHash('sha256').update(userProfile.email).digest('hex') : ''
  const sha256_phone_number = userProfile?.phoneNumber
    ? createHash('sha256').update(userProfile.phoneNumber).digest('hex')
    : ''
  const sha256_first_name = userProfile?.firstName
    ? createHash('sha256').update(userProfile.firstName).digest('hex')
    : ''
  const sha256_last_name = userProfile?.lastName ? createHash('sha256').update(userProfile.lastName).digest('hex') : ''
  const sha256_street = userProfile?.addressLineFirst
    ? createHash('sha256')
        .update(
          `${userProfile.addressLineFirst}${userProfile.addressLineSecond ? `, ${userProfile.addressLineSecond}` : ''}`,
        )
        .digest('hex')
    : ''

  return {
    sha256_email_address: [sha256_email_address],
    sha256_phone_number: [sha256_phone_number],
    email_address: userProfile?.email || '',
    phone_number: userProfile?.phoneNumber || '',
    user_id: userProfile?.id || '',
    address: [
      {
        sha256_first_name,
        sha256_last_name,
        sha256_street,
        city: userProfile?.city || '',
        region: userProfile?.countryIso || '',
        postal_code: userProfile?.postalCode || '',
        country: userProfile?.country || '',
      },
    ],
  }
}
