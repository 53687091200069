// use this function to trim the string properties of an object before sending it to the backend
export const trimStringProps = <T extends Record<string, unknown>>(obj: T, props: Array<keyof T>) => {
  const entries = Object.entries(obj).map(([key, value]) => {
    if (!props.includes(key)) return [key, value] // if the key is not in the props array, return the key-value pair as is
    if (typeof value !== 'string') return [key, value] // if the value is not a string, return the key-value pair as is
    if (!value) return [key, value] // if the value is an empty string, null, or undefined, return the key-value pair as is
    return [key, value.trim()] // trim the string value
  })

  const trimmedObj = Object.fromEntries(entries)
  return trimmedObj
}
