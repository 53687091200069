import { useRouter } from 'next/router'
import { Dispatch, ReactNode, SetStateAction, createContext, useEffect, useMemo } from 'react'
import { useLocalStorage } from 'usehooks-ts'

type AppSettings = {
  isHeaderVisible: boolean
  disableIntercom: boolean
  // Add more settings here if needed
}

type ContextProps = {
  appSettings: AppSettings
  updateAppSettings: Dispatch<SetStateAction<AppSettings>>
}

const defaultSettings: AppSettings = {
  isHeaderVisible: true,
  disableIntercom: false,
}

// This context is used to store app settings that are shared across the app
const AppSettingsContext = createContext<ContextProps>({
  appSettings: defaultSettings,
  updateAppSettings: () => undefined,
})

type AppSettingsProviderProps = {
  children: ReactNode
}

export const AppSettingsProvider = ({ children }: AppSettingsProviderProps) => {
  const [appSettings, updateAppSettings] = useLocalStorage('app-settings', defaultSettings)

  // add some global behavior here
  const router = useRouter()
  const isEmbedded = router.query.embedded === 'true'
  // hide header and disable intercom in embedded mode
  useEffect(() => {
    if (isEmbedded) {
      updateAppSettings((prev) => ({ ...prev, isHeaderVisible: false, disableIntercom: true }))
    }
  }, [isEmbedded, updateAppSettings])

  const contextValue = useMemo(() => {
    return {
      appSettings,
      updateAppSettings,
    }
  }, [appSettings, updateAppSettings])

  return <AppSettingsContext.Provider value={contextValue}>{children}</AppSettingsContext.Provider>
}

export default AppSettingsContext
