import { mutate } from 'swr'
import { authUserSwrKey } from 'constants/swrKeys'
import { NewBackEndApiResponseData } from 'shared/types/ApiResponseData'
import { apiClient } from 'utils/apiClient'
import { withApiErrorHandled } from 'utils/common'
import { UserProfile } from 'shared/types/V3/User'
import { trimStringProps } from 'shared/utils/string'

type UpdateProfileRequest = {
  firstName: string
  username: string
  fullName: string
  subtitle?: string
  [key: string]: unknown
}

async function updateProfile(body: Record<string, unknown>): Promise<UserProfile> {
  const data = await mutate<UserProfile>(
    authUserSwrKey,
    async (currentProfile?: UserProfile) => {
      if (!currentProfile) return // should not happen, because we use this function only when user is logged in
      const { firstName, username, fullName, subtitle } = currentProfile
      const bodyRequest: UpdateProfileRequest = {
        firstName,
        username,
        fullName,
        subtitle,
        ...body,
      }

      const response = await withApiErrorHandled(apiClient.put)(
        '/v2/me',
        trimStringProps(bodyRequest, ['firstName', 'username', 'email', 'fullName', 'subtitle']),
      )
      const { data } = response.data as NewBackEndApiResponseData<UserProfile>

      return data
    },
    {
      revalidate: false,
      rollbackOnError: true,
      optimisticData: (currentProfile?: UserProfile) => {
        return { ...currentProfile, ...body } as UserProfile
      },
    },
  )

  return data as UserProfile
}

export default updateProfile
