'use client'

import PropTypes from 'prop-types'
import { isServer, QueryClientProvider } from '@tanstack/react-query'

// shared - utils
import { createQueryClient } from '@shared/utils/reactQuery'

// @ts-ignore
let browserQueryClient

// get query client
// ref: https://tanstack.com/query/latest/docs/framework/react/guides/advanced-ssr#initial-setup
export const getQueryClient = () => {
  if (isServer) {
    // Server: always create a new query client
    return createQueryClient()
  }

  // Browser: create a new query client if we don't already have one
  // This is very important, so we don't re-create a new client if React
  // suspends during the initial render. This may not be needed if we
  // have a [TODO] suspense boundary BELOW the creation of the query client
  // @ts-ignore
  if (!browserQueryClient) browserQueryClient = createQueryClient()
  return browserQueryClient
}

// @ts-ignore
const ReactQueryClientProvider = ({ children }) => {
  // -- query client --
  // NOTE: Avoid useState when initializing the query client if you don't
  //       have a [TODO] suspense boundary between this and the code that may
  //       suspend because React will throw away the client on the initial
  //       render if it suspends and there is no boundary
  const queryClient = getQueryClient()

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
}
ReactQueryClientProvider.propTypes = {
  children: PropTypes.any,
}

export default ReactQueryClientProvider
