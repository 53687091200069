import qs from 'qs'
import { AxiosResponse } from 'axios'
import Big from 'big.js'
import { WEB_BASE_URL } from 'configs/index'
import { NewBackEndApiResponseData } from 'shared/types/ApiResponseData'
import isEmail from 'validator/es/lib/isEmail'
import isUrl from 'validator/es/lib/isURL'
import { QueryParameterType } from 'shared/types/Query'
import shuffle from 'lodash.shuffle'
import get from 'lodash.get'
import { isMobile } from 'react-device-detect'

// normalize the object got from backend APIs so it can be serialized as JSON and passed down to NextJS context
export const normalizeObj = (obj: Record<string, unknown>) => {
  return JSON.parse(JSON.stringify(obj))
}

// get the pagination parameters to query from page size and current item index
export const getItemPagingParams = (itemIndex: number, pageSize: number) => {
  // return query params of item in the list for pagination
  return {
    page: Math.ceil((itemIndex + 1) / pageSize),
    size: pageSize,
  }
}

// get the short formated of number to display
export const getNumberShortFormating = (num: number) => {
  if (num < 1000) return num
  return `${Number((num / 1000).toFixed(1))}K`
}

// get a validated hyperlink from a string, return null if link is not valid
export const getValidHrefFromLink = (website: string | undefined) => {
  if (!website) return null
  const prevalidWebsite = website.trim().match(/^(http|https)/i) ? website : `http://${website}`
  try {
    new URL(prevalidWebsite)
  } catch (e) {
    return null
  }

  return new URL(prevalidWebsite).href
}

// validate a string of email
export const validateEmail = (email: string) => {
  return isEmail(email)
}

// validate an URL string
export const validateUrl = (url: string) => {
  return isUrl(url)
}

// toggle selecting an item in array
export function toggleSelectingItem<T = string>(arrItems: T[], item: T) {
  const selectedIndex = arrItems.indexOf(item)
  return selectedIndex === -1
    ? [...arrItems, item]
    : [...arrItems.slice(0, selectedIndex), ...arrItems.slice(selectedIndex + 1)]
}

// toggle selecting an item in array
export function toggleSelectingObjectItemInArray<T extends Record<string, unknown>>(
  arrItems: T[],
  item: T,
  identityProp: string,
) {
  const selectedIndex = arrItems.findIndex((v) => get(v, identityProp) === get(item, identityProp))
  return selectedIndex === -1
    ? [...arrItems, item]
    : [...arrItems.slice(0, selectedIndex), ...arrItems.slice(selectedIndex + 1)]
}

// display user's location by country, state and city
export const displayUserLocation = (country: string, state?: string, city?: string) => {
  return [city, state, country].filter((v) => !!v).join(', ')
}

export const getValidHttpsLink = (link: string): string => {
  if (!link) return ''
  if (link.includes('https://')) return link
  return 'https://' + link
}

export async function wait(mseconds: number) {
  return new Promise((resolve) => setTimeout(resolve, mseconds || 1000))
}

export function isValidCryptoPrice(priceString: string, decimal = 18) {
  const priceNum = Number(priceString)
  if (isNaN(priceNum)) return false // cannot convert to a number
  if (priceNum < 0) return false // price is less than 0
  const dotIndex = priceString.indexOf('.')
  if (dotIndex === -1) return true // price is integer
  if (priceString.slice(dotIndex + 1).length > decimal) return false // have precision large than [number of] decimal places
  return true
}

export function normalizeImageSize(
  _width: number,
  _height: number,
  _wrapperWidth: number,
  _wrapperHeight: number,
): [width: number, height: number] {
  // const d1 = wrapperWidth / wrapperHeight
  const [wrapperWidth, wrapperHeight] = [Math.floor(_wrapperWidth), Math.floor(_wrapperHeight)]

  const [width1, height1] = [_width * (wrapperHeight / _height), wrapperHeight]
  if (width1 <= wrapperWidth) {
    return [width1, height1]
  }

  const [width2, height2] = [wrapperWidth, _height * (wrapperWidth / _width)]
  return [width2, height2]
}

export function randomIntFromInterval(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type WithApiErrorHandledParamsType = any
export function withApiErrorHandled<T>(
  handler: (...params: Array<WithApiErrorHandledParamsType>) => Promise<AxiosResponse<NewBackEndApiResponseData<T>>>,
  generalError?: string,
) {
  return async (...params: Array<WithApiErrorHandledParamsType>) => {
    const response = await handler(...params)
    const { ok, message, errorCode, errors } = response.data as NewBackEndApiResponseData<T>
    if (!ok) {
      const errorMessage = typeof message === 'string' ? message : generalError ?? 'An error occured'
      throw errorCode || errors ? { message: errorMessage, errorCode, errors } : errorMessage
    }

    return response
  }
}

export function createDeepLinkToPagePath(pagePath: string) {
  return `https://${WEB_BASE_URL}${pagePath}`
}

export function formatUsPrice(price: number, minimumFractionDigits = 0, maximumFractionDigits?: number) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits,
    maximumFractionDigits,
  })

  return formatter.format(price).replace('$', '')
}

export const filterListObjectsByString = <T extends { [key: string]: unknown }>(
  list: T[],
  query: string,
  valueKey: string,
) => {
  return list.filter((item: T) => {
    const trimmedLowercaseQuery = query.trim().toLowerCase()
    const trimmedLowercaseValue = ((item?.[valueKey] as string) || '').trim().toLowerCase()
    if (!trimmedLowercaseQuery) return true
    return trimmedLowercaseValue.includes(trimmedLowercaseQuery)
  })
}

export const roundNumberByDecimal = (num: number | string, decimal: number) => {
  const value = typeof num === 'string' ? parseFloat(num) : num
  return Math.round(value * 10 ** decimal + Number.EPSILON) / 10 ** decimal
}

/**
 * isDOM - Check if the code is running in a browser
 *
 * @return {boolean}  Returns true if the code is running in a browser
 */

export const isDOM = () => {
  const isDOM = typeof window !== 'undefined' && Boolean(window?.document?.documentElement)
  return isDOM
}

/**
 * Removes query parameters from an object that are empty strings or empty arrays
 * @param {object} params - object of key-value pairs to be cleaned
 * @returns {object} - object with all falsy values removed
 */

export const cleanEmptyParamsFromQueryObject = (params: {
  [key: string]: undefined | QueryParameterType | Array<QueryParameterType>
}) => {
  return Object.entries(params).reduce((result, [key, value]) => {
    if (value == null || value === '') return result
    if (Array.isArray(value) && value.length === 0) return result
    return { ...result, [key]: value }
  }, {})
}

export const queryStringParamsSerializer = (params: Record<string, unknown>) => {
  return qs.stringify(params, { arrayFormat: 'repeat' })
}

export const parseQuerySingleParamValue = (queryParamValue: string | undefined, paramType: QueryParameterType) => {
  if (!queryParamValue) return undefined
  if (paramType === 'number') return Big(queryParamValue).toNumber()
  if (paramType === 'string') return queryParamValue
  if (paramType === 'boolean') return queryParamValue === 'true'
  return undefined
}

export const parseQueryArrayParamValue = (
  queryParamValue: string | string[] | undefined,
  paramType: QueryParameterType,
) => {
  if (!queryParamValue) return []

  if (Array.isArray(queryParamValue)) {
    if (paramType === 'number') return queryParamValue.map((value) => Big(value).toNumber())
    if (paramType === 'string') return queryParamValue
    if (paramType === 'boolean') return queryParamValue.map((value) => value === 'true')
    return []
  }

  if (paramType === 'number') return [Big(queryParamValue).toNumber()]
  if (paramType === 'string') return [queryParamValue]
  if (paramType === 'boolean') return queryParamValue === 'true'
}

// get full address from addressLine1 and addressLine2
export const getFullAddress = (addressLine1: string | null, addressLine2: string | null) => {
  const line1 = addressLine1 || ''
  const line2 = addressLine2 || ''
  const address = line1 + (line2 ? ', ' + line2 : '')
  return address
}

export const reorderItemInList = <T>(list: T[], startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

export const getYouTubeVideoId = (url: string) => {
  try {
    // Create a new URL object with the input URL
    const videoUrl = new URL(url)
    // Get the value of the 'v' parameter from the URL's search parameters
    const videoId = videoUrl.searchParams.get('v')
    // If a video ID is found, return it
    if (videoId) return videoId

    // If the URL is in the 'youtu.be' format, extract the video ID from the path
    if (videoUrl.host !== 'youtu.be') return null
    const pathParts = videoUrl.pathname.split('/')
    if (pathParts.length > 1) return pathParts[1]

    // Return null if no video ID is found
    return null
  } catch (e) {
    console.error(e)
    return null
  }
}

// return true if the browser supports native share
export const nativeShare = async (data: ShareData, fallbackText: string) => {
  if (isMobile && navigator.share && navigator.canShare(data)) {
    await navigator.share(data)
    return true
  }
  // Do something else like copying the data to the clipboard
  await navigator.clipboard.writeText(fallbackText)
  return false
}

export function getRandomItemsFromArray<T>(array: T[], num: number) {
  return shuffle(array).slice(0, num)
}

/**
 * Opens a relative link in a new tab or the current tab.
 * @param relativeLink - The relative link to open.
 * @param openInNewTab - Optional. Specifies whether to open the link in a new tab. Default is true.
 * @warning This function won't work for iOS's Safari browser if it's called inside an async function.
 * @see https://stackoverflow.com/a/39387533
 */
export function openRelativeLink(relativeLink: string, openInNewTab = true) {
  const url = new URL(relativeLink, window.location.href)
  window.open(url.href, openInNewTab ? '_blank' : '_self')
}

// function to test if an array has duplicated ids ()
export function _DEV_hasDuplicatedIds(ids: number[]) {
  const uniqueIds = new Set(ids)
  return uniqueIds.size !== ids.length
}

export function isHumanUserBrowswer() {
  try {
    return isDOM() && !navigator.userAgent.includes('Linux x86_64')
  } catch (e) {
    return false
  }
}
