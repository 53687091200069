import { AnalyticsBrowser, Options } from '@segment/analytics-next'
import { IS_DEVELOPMENT } from 'configs/index'
import { EventName } from 'shared/types/V3/Segment'

const apiKey = process.env.SEGMENT_API_KEY

class SegmentClient {
  private segment?: AnalyticsBrowser

  get isLoaded() {
    return Boolean(this.segment)
  }

  async reset() {
    if (!this.segment) return

    this.segment.reset()
  }

  async load() {
    if (this.segment) {
      console.warn('Segment already loaded')
      return
    }

    this.segment = new AnalyticsBrowser()
    if (apiKey) await this.segment.load({ writeKey: apiKey })
    else console.error('API KEY for segment is missing')
  }

  async track(
    eventName: EventName,
    properties?: Record<
      string,
      Array<number | string | boolean> | object | number | string | boolean | null | undefined
    >,
    eventOptions?: Options,
  ) {
    if (IS_DEVELOPMENT) {
      console.log('Segment event', eventName)
      console.table(properties)
    }

    if (!this.segment || !this.isLoaded) return

    await this.segment.track(eventName, properties, eventOptions)
  }

  async identify(userId: string | null | undefined, traits?: Record<string, unknown>) {
    if (!this.segment) return

    if (IS_DEVELOPMENT) {
      console.log('Segment identify', userId)
      console.table(traits)
    }

    return this.segment.identify(userId, traits)
  }
}

export const segmentClient = new SegmentClient()

export const loadSegmentClient = segmentClient.load.bind(segmentClient)
export const trackSegmentCustomEvent = segmentClient.track.bind(segmentClient)
export const identifySegmentUser = segmentClient.identify.bind(segmentClient)
export const resetSegmentUser = segmentClient.reset.bind(segmentClient)
