import { NextRouter, useRouter } from 'next/router'
import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useRef } from 'react'

type CustomRouterContextType = {
  goBack: (fallbackUrl?: string) => Promise<void>
  router: NextRouter
}

const CustomRouterContext = createContext<CustomRouterContextType>({} as CustomRouterContextType)

export const CustomRouterProvider = ({ children }: { children: ReactNode }) => {
  const router = useRouter()
  const lastPageRef = useRef<string | null>(null)
  const currentPageRef = useRef<string>(router.asPath)

  // listen to router change
  useEffect(() => {
    const onRouteChangeComplete = (url: string) => {
      lastPageRef.current = currentPageRef.current
      currentPageRef.current = url
    }

    router.events.on('routeChangeStart', onRouteChangeComplete)
    return () => {
      router.events.off('routeChangeStart', onRouteChangeComplete)
    }
  }, [router.events])

  const goBack = useCallback(
    async (fallbackUrl = '/') => {
      if (lastPageRef.current && lastPageRef.current !== currentPageRef.current) {
        await router.back()
      } else {
        await router.replace(fallbackUrl)
      }
    },
    [router],
  )

  const contextValue: CustomRouterContextType = useMemo(() => {
    return {
      goBack,
      router,
    }
  }, [goBack, router])

  return <CustomRouterContext.Provider value={contextValue}>{children}</CustomRouterContext.Provider>
}

export const useCustomRouter = () => {
  const context = useContext(CustomRouterContext)
  if (context === undefined) {
    throw new Error('useHistory must be used within a HistoryProvider')
  }

  return context
}
