'use client'

import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Provider as JotaiProvider, createStore } from 'jotai'

// @shared - providers
import ReactQueryClientProvider from '@shared/components/Providers/ReactQueryClientProvider'

// Opt out of caching for all data requests in the route segment
// export const dynamic = 'force-dynamic'
// export const revalidate = 0

// @ts-ignore
const AppProviders = ({ children }) => {
  // create jotai store
  const store = useMemo(() => createStore(), [])

  return (
    <ReactQueryClientProvider>
      <JotaiProvider store={store}>{children}</JotaiProvider>
    </ReactQueryClientProvider>
  )
}

AppProviders.propTypes = {
  children: PropTypes.any,
}

export default AppProviders
