import { createContext, ReactNode, useCallback, useContext, useRef, useState } from 'react'

import { useBoolean } from 'usehooks-ts'
import ConfirmModal, { ConfirmModalProps } from 'shared/components/modal/ConfirmModal'

type ConfirmModalContent = Omit<ConfirmModalProps, 'onClose' | 'onConfirm' | 'onCancel' | 'open'>

type ConfirmModalOptions = {
  onConfirm?: () => void
  onCancel?: () => void
  delay?: number
  disableBackdropClick?: boolean
}

type ConfirmModalContextType = (modalContent: ConfirmModalContent, options?: ConfirmModalOptions) => void

const ConfirmModalContext = createContext<ConfirmModalContextType>(() => undefined)

type ConfirmModalProviderProps = {
  children: ReactNode
}

export const ConfirmModalProvider = ({ children }: ConfirmModalProviderProps) => {
  const { value: isShowingModal, setTrue, setFalse: hideModal } = useBoolean(false)
  const [modalContent, setModalContent] = useState<ConfirmModalContent>({
    title: 'Confirm Modal',
    description: 'description',
    confirmBtnText: 'Confirm',
    cancelBtnText: 'Cancel',
  })

  const onConfirmCallback = useRef<() => void>()
  const onCancelCallback = useRef<() => void>()
  const disableBackdropClickRef = useRef<boolean | undefined>(true)

  const showModal = useCallback(
    (content: ConfirmModalContent, options?: ConfirmModalOptions) => {
      // set new content
      setModalContent(content)
      // set new callbacks
      onConfirmCallback.current = options?.onConfirm
      onCancelCallback.current = options?.onCancel
      disableBackdropClickRef.current = options?.disableBackdropClick
      // show modal with a delay
      const delay = options?.delay || 0
      delay > 0 ? setTimeout(setTrue, options?.delay) : setTrue()
    },
    [setTrue],
  )

  const onCloseModal = useCallback(() => {
    hideModal()
  }, [hideModal])

  const onConfirm = useCallback(() => {
    hideModal()
    onConfirmCallback.current?.()
  }, [hideModal])

  const onCancel = useCallback(() => {
    hideModal()
    onCancelCallback.current?.()
  }, [hideModal])

  return (
    <ConfirmModalContext.Provider value={showModal}>
      {children}
      <ConfirmModal
        open={isShowingModal}
        title={modalContent.title}
        description={modalContent.description}
        hideCancelButton={modalContent.hideCancelButton}
        confirmButtonVariant={modalContent.confirmButtonVariant}
        onClose={onCloseModal}
        onConfirm={onConfirm}
        onCancel={onCancel}
        confirmBtnText={modalContent.confirmBtnText || 'Confirm'}
        cancelBtnText={modalContent.cancelBtnText || 'Cancel'}
        content={modalContent.content}
        disableBackdropClick={disableBackdropClickRef.current}
      />
    </ConfirmModalContext.Provider>
  )
}

export const useConfirmModal = () => {
  const context = useContext(ConfirmModalContext)
  return context
}
