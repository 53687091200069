import axios from 'axios'

// API client to integrate with Cohart Web internal API
export const webApiClient = axios.create({
  baseURL: '/api',
  validateStatus: () => true, // axios throws error when status code is not 2xx or 3xx, makes the web crashes
  withCredentials: true,
})

export default webApiClient
