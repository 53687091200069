import { NewBackEndApiResponseData } from 'shared/types/ApiResponseData'
import { apiClient } from 'utils/apiClient'
import { withApiErrorHandled } from 'utils/common'
import { SiteMetadata } from 'shared/types/App'
import { TagBase } from 'shared/types/V3/Tag'

function moveOtherTagToTheListEnd(tags: Array<TagBase>) {
  const otherTagIndex = tags.findIndex((tag) => tag.title.toLowerCase() === 'other')
  if (otherTagIndex === -1) return tags // not found `other` tag
  return [...tags.slice(0, otherTagIndex), ...tags.slice(otherTagIndex + 1), tags[otherTagIndex]] // move the tag to the list end
}

async function getMetadata() {
  const response = await withApiErrorHandled(apiClient.get)('/v2/public/metadata')
  const { data } = response.data as NewBackEndApiResponseData<SiteMetadata>
  const siteMetadata: SiteMetadata = {
    ...data,
    moods: moveOtherTagToTheListEnd(data.moods),
    tags: moveOtherTagToTheListEnd(data.tags),
    mediums: moveOtherTagToTheListEnd(data.mediums),
    values: moveOtherTagToTheListEnd(data.values),
  }

  return siteMetadata
}

export default getMetadata
