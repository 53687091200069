import { ReactNode, useCallback } from 'react'
import cn from 'classnames'

import CloseIcon from 'shared/icons/CloseIcon'
import ModalWrapper from 'shared/components/modal/ModalWrapper'
import SimpleBar from 'simplebar-react'

export type ConfirmModalProps = {
  open: boolean
  title: ReactNode
  content?: ReactNode // additional content if needed
  description: ReactNode
  onClose: () => void
  onConfirm?: () => void
  onCancel?: () => void
  confirmBtnText?: string
  cancelBtnText?: string
  hideCancelButton?: boolean
  confirmButtonVariant?: 'default' | 'warning' | 'success'
  reverseButtonOrder?: boolean
  disableBackdropClick?: boolean
}

const ConfirmModal = (props: ConfirmModalProps) => {
  const {
    open,
    onClose,
    title,
    description,
    onCancel,
    onConfirm, //
    confirmBtnText,
    cancelBtnText,
    hideCancelButton,
    content,
    reverseButtonOrder,
    confirmButtonVariant = 'default',
    disableBackdropClick,
  } = props

  const onConfirmBtnClick = useCallback(() => {
    typeof onConfirm === 'function' && onConfirm()
  }, [onConfirm])

  const onCancelBtnClick = useCallback(() => {
    typeof onCancel === 'function' && onCancel()
  }, [onCancel])

  return (
    <ModalWrapper open={open} onClose={onClose} disableBackdropClick={disableBackdropClick}>
      <div className="max-h-[65vh] w-[calc(100vw-56px)] max-w-[100vw] lg:w-[440px]">
        <div className="relative flex h-full w-full flex-col overflow-hidden rounded-[24px] bg-white">
          {/* title */}
          <div className="sticky top-0 z-10 inline-flex w-full items-center justify-between px-4 py-4 lg:px-6">
            <h2 className="text-[14px] font-medium uppercase text-kokushoku-black">{title}</h2>
            <div>
              <button onClick={onClose}>
                <CloseIcon className="!h-4 !w-4" />
              </button>
            </div>
          </div>
          {/* content */}
          <div className="w-full flex-1 overflow-y-hidden scroll-smooth">
            <SimpleBar className="max-h-full max-w-full" autoHide={false}>
              <div className="w-full p-4 lg:p-6">
                <div className="mb-4 empty:mb-0 lg:mb-6">{content}</div>
                <p className="text-[14px] text-kokushoku-black">{description}</p>
              </div>
            </SimpleBar>
          </div>
          {/* buttons */}
          <div
            className={cn(
              'flex w-full items-center justify-between border border-t border-black/10 text-[17px] text-kokushoku-black',
              reverseButtonOrder ? 'flex-row-reverse' : 'flex-row',
            )}
          >
            {!hideCancelButton && (
              <button
                className="flex flex-1 items-center justify-center px-4 py-4 text-xs transition-colors hover:bg-black/10 disabled:opacity-40 md:text-base lg:px-6"
                onClick={onCancelBtnClick}
              >
                {cancelBtnText}
              </button>
            )}
            <button
              className={cn(
                'border-1 flex flex-1 items-center justify-center border-l border-black/10 px-4 py-4 text-xs font-semibold',
                'transition-colors hover:bg-black/10 disabled:opacity-40 md:text-base lg:px-6',
                confirmButtonVariant === 'warning' && 'text-error',
                confirmButtonVariant === 'default' && 'text-black',
                confirmButtonVariant === 'success' && 'text-brand-green',
              )}
              onClick={onConfirmBtnClick}
            >
              {confirmBtnText}
            </button>
          </div>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default ConfirmModal
