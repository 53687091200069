/* eslint-disable @typescript-eslint/no-explicit-any */
class Logger {
  constructor() {
    // constructor
  }

  /** Logs to all the error logging service that we have */
  logError<T extends Record<string, unknown> | Error>(error: T, options?: Record<string, unknown>): void {
    // TODO: add HoneyBadger logging
    console.error(error, options)
  }
}

const loggerInstance = new Logger()

type HandlerType<T extends any[], I> = (...params: T) => Promise<I> | I
type LoggerOptions = {
  action: string
  customErrorText?: string
  [key: string]: unknown
}

export function withLogger<T extends any[], I>(handler: HandlerType<T, I>, options?: LoggerOptions) {
  return async (...params: T) => {
    try {
      const result = await handler(...params)
      return result
    } catch (error) {
      const { customErrorText } = options || {}
      const message = typeof error === 'string' ? error : customErrorText ?? 'An error occured'
      loggerInstance.logError(error as Error)
      throw message
    }
  }
}

export default loggerInstance
