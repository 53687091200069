import { useLocalStorage } from 'usehooks-ts'
import { UserWebInfoType, WebInfoType } from 'shared/types/App'
import { useCallback, useEffect, useState } from 'react'
import useAuth from './authentication/useAuth'

const useShowingInfoOnce = (infoName: keyof WebInfoType, timeOut = 100) => {
  const { authUser, isFetching } = useAuth()
  const [webState, setWebState] = useLocalStorage<UserWebInfoType>('web_state', { guest: {} })
  // mark as shown if it's on server side rendering
  const [isInfoShown, _setInfoShown] = useState(true)

  const username = isFetching ? undefined : authUser?.username || 'guest'

  useEffect(() => {
    if (!username) return // still fetching for authUser
    // to make sure the tutorialState is updated and only on client side, since ssr doesn't have localStorage
    const setInfoTimeOut = setTimeout(() => {
      _setInfoShown(Boolean(webState?.[username]?.[infoName]))
    }, timeOut)

    return () => clearTimeout(setInfoTimeOut)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoName, username])

  const setInfoShown = useCallback(
    (isShown: boolean) => {
      if (!username) return // still fetching for authUser
      _setInfoShown(isShown)
      setWebState((prevWebState) => ({
        ...prevWebState,
        [username]: {
          ...prevWebState?.[username],
          [infoName]: isShown,
        },
      }))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [infoName, username],
  )

  return [isInfoShown, setInfoShown] as [boolean, (isShown: boolean) => void]
}

export default useShowingInfoOnce
