// can not use object destructuring for env var
// since dynamic lookup will not be inline when reading env var for browser
// ref: https://nextjs.org/docs/pages/building-your-application/configuring/environment-variables#bundling-environment-variables-for-the-browser
export const NODE_ENV = process.env.NODE_ENV
export const WEB_URL = process.env.WEB_URL
export const SENTRY_DSN_URL = process.env.SENTRY_DSN_URL

export const DEV = NODE_ENV === 'development'
export const PROD = NODE_ENV === 'production'
export const TEST = NODE_ENV === 'test'

// check current render is server side or not
export const IS_SERVER = typeof window === 'undefined'
