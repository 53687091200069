import useSWRImmutable from 'swr/immutable'
import getMetadata from 'shared/services/public/getMetadata'
import swrKeys from 'constants/swrKeys'

function useFetchingMetadata() {
  const { data: metadata, isLoading } = useSWRImmutable(swrKeys.metadata, getMetadata)
  return { metadata, isLoading }
}

export default useFetchingMetadata
