import { GENERAL_ERROR, GENERAL_SUCCESS } from 'configs'
import { ReactNode } from 'react'
import { toast, ToastContent, ToastOptions, ToastPromiseParams } from 'react-toastify'

export const notifyError = (content?: unknown, options?: ToastOptions) => {
  const errorMessage = content instanceof Error ? content.message : typeof content === 'string' ? content : undefined

  toast.error(errorMessage || GENERAL_ERROR, {
    autoClose: 10000, // show error notification for 10sec, default is 5 seconds
    ...options,
  })
}

export const notifySuccess = (content?: ToastContent, options?: ToastOptions) => {
  toast.success(content || GENERAL_SUCCESS, options)
}

export const notifyInfo = (content: ReactNode, options?: ToastOptions) => {
  toast(content, options)
}

export const notifyPromise = async (
  promise: Promise<unknown> | (() => Promise<unknown>),
  toastPromiseParams?: ToastPromiseParams,
  options?: ToastOptions,
) => {
  return toast.promise(
    promise,
    {
      success: toastPromiseParams?.success,
      pending: toastPromiseParams?.pending,
      error: toastPromiseParams?.error || GENERAL_ERROR,
    },
    options,
  )
}
