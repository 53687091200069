import axios from 'axios'

export const apiClient = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  validateStatus: () => true,
  withCredentials: true, // add cookie to requests
  headers: {
    'Accept-Version': '2.0.x',
  },
})

// to be used in server-side, cannot get cookie from server-side
export const apiClientSsr = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  validateStatus: () => true,
  headers: {
    'Accept-Version': '2.0.x',
  },
})
