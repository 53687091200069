import { NewBackEndApiResponseData } from 'shared/types/ApiResponseData'
import { InviteFormType } from 'shared/types/V3/User'
import { apiClient } from 'utils/apiClient'
import { withLogger } from 'utils/Logger'

const inviteCollector = async (inviteData: InviteFormType & { testimonialId?: number }) => {
  const response = await apiClient.post<NewBackEndApiResponseData>('/v2/me/invite-collector', inviteData)

  return response.data
}

export default withLogger(inviteCollector, {
  action: 'invite_collector',
  customErrorText: 'Cannot invite collector',
})
