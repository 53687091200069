import { NewBackEndApiResponseData } from 'shared/types/ApiResponseData'
import { UserProfile } from 'shared/types/V3/User'
import { withApiErrorHandled } from 'utils/common'
import { withLogger } from 'utils/Logger'
import webApiClient from 'utils/webApiClient'

const getAuthUser = async () => {
  const response = await withApiErrorHandled(webApiClient.get)('/auth/info')
  const { data } = response.data as NewBackEndApiResponseData<UserProfile>
  return data
}

export default withLogger(getAuthUser, {
  action: 'get_auth_user',
  customErrorText: 'Cannot get auth user',
})
