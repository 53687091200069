import AppLayoutContext, { AppLayout } from 'contexts/AppLayoutContext'
import { useCallback, useContext, useEffect, useRef } from 'react'

const useAppLayout = (layoutOptions: AppLayout = {}) => {
  const context = useContext(AppLayoutContext)
  const { setAppLayout } = context
  const { fullHeightSidebar, isMobileSidebarVisible } = layoutOptions

  const watchAppLayoutPropChangeRef = useRef(<K extends keyof AppLayout>(propName: K, value: AppLayout[K]) => {
    if (value === undefined) return // do nothing if the value is not defined
    setAppLayout((prev) => ({ ...prev, [propName]: value }))
  })

  useEffect(() => {
    watchAppLayoutPropChangeRef.current('fullHeightSidebar', fullHeightSidebar)
  }, [fullHeightSidebar])

  useEffect(() => {
    watchAppLayoutPropChangeRef.current('isMobileSidebarVisible', isMobileSidebarVisible)
  }, [isMobileSidebarVisible])

  const toggleMobileSidebar = useCallback(() => {
    setAppLayout((prev) => {
      return { ...prev, isMobileSidebarVisible: !prev.isMobileSidebarVisible }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    toggleMobileSidebar,
    ...context,
  }
}

export default useAppLayout
